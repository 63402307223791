@charset "UTF-8";

/* reset
------------------------------------------------------------------------------*/
html {
  color: #000;
  background: #f8f8f8;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
select,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0;
  vertical-align: bottom;
  line-height: 0;
  image-rendering: -webkit-optimize-contrast;
}

address,
button,
caption,
cite,
code,
dfn,
em,
input,
optgroup,
option,
select,
strong,
textarea,
th,
var {
  /* font: inherit; */
}

li {
  list-style: none;
}

sup,
sub {
  vertical-align: baseline;
}

.pc {
  display: none;
}

.sp {
  display: inline;
}

/* common
------------------------------------------------------------------------------*/
body {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-font-feature-settings: "palt"1;
  font-feature-settings: "palt"1;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.075em;
  color: #47525a;
}

a,
a:link {
  color: #0975b9;
  text-decoration: underline;
}

a:visited {
  color: #0975b9;
  text-decoration: underline;
}

a:active {
  color: #0975b9;
  text-decoration: none;
}

a:active {
  color: #0975b9;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

sup {
  vertical-align: super;
  font-size: 0.5em;
  line-height: 0.1;
}

img {
  width: 100%;
  height: auto;
  image-rendering: auto;
}

/* layout
------------------------------------------------------------------------------*/
.w40 {
  width: 40%;
}

#form section .d-block {
  display: block;
}

/* h1, p.lead
------------------------------------------------------------------------------*/
#header {
  background: left 10px top 10px no-repeat #fff;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.075em;
  font-weight: normal;
  text-align: center;
  position: relative;
  background-size: auto 60%;
}

h1 span {
  position: absolute;
  z-index: 10;
  width: 70%;
  margin-left: 15%;
  display: block;
  line-height: 1.2;
}

/* h1::before {
  content: "";
  width: 100px;
  height: 83px;
  left: 0;
  top: 0;
  position: absolute;
} */

/* h1::after {
  content: "";
  /* background: url("../public/form_ttl_bg_r.svg") right/100% auto no-repeat; */
/* width: 100px;
  height: 83px;
  right: 0;
  top: 0;
  position: absolute;
}  */

p.lead {
  padding: 16px;
  text-align: center;
  font-size: 13px;
}

/* #form
------------------------------------------------------------------------------*/
#form {
  position: relative;
  background: #fff;
  margin: 8px;
  flex: 1;
}

#form section {
  padding: 20px;
}

#form section.area {
  display: flex;
  flex-flow: column;
  padding: 0;
}

#form section+section {
  border-top: #e4e9ed solid 1px;
}

#form section h2 {
  /* background: url("./form_cpt_icn.svg") left 0px / 27px auto no-repeat; */
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.43;
  letter-spacing: 0.075em;
  padding-top: 3px;
}

#form section h3 {
  background: #e4e9ed;
  color: #47525a;
  font-size: 15px;
  font-weight: normal;
  padding: 6px 10px 6px 10px;
  margin-bottom: 16px;
}

#form section dt {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.075em;
  margin-bottom: 2px;
  vertical-align: middle;
  position: relative;
}

#form section dt span {
  font-size: 10px;
  margin-left: 8px;
  color: red
}

#form section dt.required::before,
#form section h2.required::before,
#form section h3.required::before,
#form section .certification-box_txt.required::before {
  content: "必須";
  background: red;
  border-radius: 2px;
  border: none;
  color: #fff;
  margin: 0px 4px 0px 0px;
  padding: 2px 4px 4px;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  bottom: 2px;
}

#form section h3::after,
#form section h3.required::before {
  margin-top: 0;
}

#form section div.formSet {
  padding-bottom: 1px;
}

#form section div.formSet+div.formSet {
  margin-top: 0;
}

#form section div.formSet .annotation {
  text-align: center;
}

#form section div.formSet .annotation.txt_placeholder {
  color: #b5c6d2;
}

.yubin {
  background-color: #e4e9ed;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

/* input:text */
#form section dd,
#form section p {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

#form section p span {
  margin: 0 1px;
}

#form section div.explanation-box {
  margin: 0 0 16px;
  text-align: center;
  display: block;
}

#form section p.explanation-box_txt {
  font-size: 10px;

  background-color: #e4e9ed;
  display: inline;
  padding: 8px 16px;
}

#form section dd input,
#form section p input {
  width: 100%;
  border-radius: 4px;
  color: #47525a;
  border: #b5c6d2 solid 1px;
  height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
}

#form section div textarea {
  resize: none;
  width: 100%;
  border-radius: 4px;
  color: #47525a;
  border: #b5c6d2 solid 1px;
  height: 36px;
  padding: 8px;
  font-size: 14px;
  min-height: 300px;
}

#form section p.annotation {
  font-size: 10px;
  margin: -14px 0 16px 0;
}

#form section p.annotation.ml {
  margin: -14px 0 16px 36px;
}

#form section dd input::placeholder,
#form section p input::placeholder {
  color: #b5c6d2;
}

#form section .multiNum p input {
  width: 0%;
  margin: 0;
}

.multiNum .fg01 {
  flex-grow: 1;
}

.multiNum .fg02 {
  flex-grow: 2;
}

.multiNum .fg03 {
  flex-grow: 3;
}

.multiNum .fg04 {
  flex-grow: 4;
}

.multiNum .fg05 {
  flex-grow: 5;
}

.multiNum .fg06 {
  flex-grow: 6;
}

.multiNum .fg07 {
  flex-grow: 7;
}

.multiNum .fg08 {
  flex-grow: 8;
}

.multiNum .fg09 {
  flex-grow: 9;
}

/* input:radio */
#form section .radio {
  margin-bottom: 16px;
}

#form section .radio input {
  display: none;
}

#form section .radio dd input {
  display: block;
}

#form section .radio .radio_ml {
  margin: 1px 0 0 34px;
}

#form section .radio label {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.075em;
  padding: 6px 0 6px 33px;
}

#form section .radio label::before {
  content: "";
  display: block;
  box-sizing: border-box;
  background: #fff;
  border: #b5c6d2 solid 1px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: 5px;
}

#form section .radio input:checked+label::after {
  content: "";
  display: block;
  box-sizing: border-box;
  background: #0f92d4;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  left: 2px;
  top: 7px;
}

/* select */
#form section .select,
#form section select {
  width: 100%;
  appearance: none;
  background: #fff;
  border-radius: 4px;
  color: #47525a;
  padding: 0 40px 0 0px;
  border: #b5c6d2 solid 1px;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.075em;
  margin-bottom: 16px;
  height: 36px;
  position: relative;
}

#form section dd .select {
  margin-bottom: 0;
}

#form section .select {
  border: none;
  background: inherit;
}

#form section select {
  position: absolute;
  left: 0;
  top: 0;
}

#form section .select::before {
  content: "";
  background: url("../public/form_select_icn.svg") left top / 100% auto no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5px;
  top: 6px;
  margin-top: 0;
}

#form section .custom {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
}

#form section .rs__value-container {
  height: 36px;
}


/* 認証コード */
.certification-box {
  display: flex;
  background-color: white;
  border-radius: 8px;
  justify-content: center;
  width: 80%;
  margin: 0 auto 16px;
  align-items: center;
  padding: 8px 0;
}

#form section .certification-box_txt {
  margin-right: 16px;
}

#form section .certification-box p {
  margin-bottom: 0;
}

#form section .certification-box p input {
  width: 80px;
}

@media only screen and ((max-width: 380px)) {
  #form section .certification-box p input {
    width: 50px;
  }

  #form section .certification-box_txt {
    margin-right: 8px;
    font-size: 15px;
  }
}

@media only screen and ((max-width: 320px)) {
  #form section .certification-box_txt {
    margin-right: 4px;
    font-size: 13px;
  }
}

.txtlink-box {
  text-align: center;
}

#form section .txtlink-box p {
  display: block;
  font-size: 13px;
}

/* 郵便番号検索 */
#form section .zip dt {
  position: relative;
}

#form section .zip dd input {
  width: 140px;
}

#form section .zip dd input.zipSearchBtn {
  appearance: none;
  -webkit-appearance: none;
  height: 36px;
  border-radius: 4px;
  background: #3ca433;
  color: #fff;
  text-align: center;
  margin-left: 8px;
  border: none;
  font-size: 15px;
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

#form section .zip dd input.zipSearchBtn:active {
  transition: 0.3s;
  background: #195413;
}

/* カレンダー */
#form section .calendar p input {
  width: 200px;
}

#form section .calendar p input.calendarBtn {
  /* background: url("../public/form_calendar_icn.svg") left top / 100% auto no-repeat; */
  width: 36px;
  height: 36px;
  margin-left: 8px;
  border: none;
}

.calendar label::before {
  position: absolute;
  content: "";
  right: -43px;
  width: 36px;
  height: 36px;
  background-color: #06c;
  /* background: url("../public/form_calendar_icn.svg") left top / 100% auto no-repeat; */
  background-repeat: no-repeat;
  background-position: center;
}

/* .submit */

.submit-mb {
  margin-bottom: 90px;
}

#form section+section.submit {
  border-top: none;
  background: #fff;
  padding: 0 0 10px 0;
  width: 100%;
}

#form section+section.submit ul {
  display: flex;
}

#form section+section.submit ul li {
  width: 100%;
}

#form section+section.submit ul li+li {
  margin-left: calc(16 / 750 * 100vw);
}

#form section+section.submit ul li button {
  background: none;
  border: none;
  display: block;
  width: 100%;
}

#form section+section.submit ul li button span {
  display: block;
  box-sizing: border-box;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: 0.075em;
  font-weight: bold;
  text-align: center;
  padding-top: 13px;
}

#form section.submit ul li button.activeBtn span {
  background: #3ca433;
  border: #3ca433 1px;
  border-radius: 10px;
  color: #fff;
  width: 80%;
  margin: auto;
}

#form section.submit ul li button.activeBtn span:active {
  background: #195413;
  transition: 0.3s;
}

#form section.submit ul li button.disabledBtn span {
  background: grey;
  border: grey 1px;
  border-radius: 10px;
  color: #fff;
  width: 80%;
  margin: auto;
}

#form section.submit ul li button.cancelBtn span {
  background: #fff;
  border: #5c7180 solid 1px;
  color: #5c7180;
  white-space: pre-line;
}

/* .validation */
.search-yubin .validation-error-txt {
  font-size: 15px;
  margin-top: -14px;
  color: #f44336;
  white-space: pre-line;
}
.validation-error-txt {
  font-size: 10px;
  margin-top: -14px;
  color: #f44336;
  white-space: pre-line;
}

.validation-error-txt02 {
  font-size: 10px;
  margin-top: -30px;
  color: #f44336;
  white-space: pre-line;
}

#form section input.is-invalid {
  border: #f44336 solid 1px;
  background-color: #feeceb;
}

#form section select.is-invalid {
  border: #f44336 solid 1px;
  background-color: #feeceb;
}

#form section .is-select-invalid {
  border: #f44336 solid 1px;
  background-color: #feeceb;
}

#form .validation-error-box {
  margin: 16px 20px;
  border: solid 2px #f44336;
  padding: 10px 10px 10px 28px;
  color: #f44336;
  background-color: #feeceb;
  font-size: 13px;
  position: relative;
  white-space: pre-line;
}

#form .validation-error-box p::before {
  content: "";
  /* background: url("../public/info_error.svg") left top / 100% auto no-repeat; */
  width: 16px;
  height: 16px;
  position: absolute;
  /* right: 10px; */
  top: 10px;
  left: 7px;
  margin-top: 0;
  z-index: 12;
}

/* #footer
------------------------------------------------------------------------------*/
footer {
  background: #5c7180;
  padding: 8px 0;
  text-align: center;
}

footer p.copyright {
  color: #fff;
  font-size: 8px;
  line-height: 1;
  letter-spacing: 0.075em;
}

/* #PC
------------------------------------------------------------------------------------------------------------------------------------------------------------*/

@media only screen and ((min-width: 959px)) {
  .pc {
    display: inline;
  }

  .sp {
    display: none;
  }

  p.lead {
    font-size: 20px;
  }

  #form section .txtlink-box p {
    font-size: 20px;
  }

  .certification-box {
    margin: 0 auto 30px;
  }

  #form section .certification-box_txt {
    font-size: 20px;
  }

#header  {
    background: left 50px top 10px no-repeat #fff;
    min-width: 1000px;
    padding-top: 40px;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    font-size: 33px;
    line-height: 1;
    letter-spacing: 0.075em;
    font-weight: normal;
    text-align: center;
    background-size: auto 60%;
  }

  h1::before,
  h1::after {
    content: "";
    background: none;
  }

  #form section h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  #form section h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  #form {
    width: 90%;
    max-width: 1000px;
    margin: 20px auto 0;
  }

  #form section dd,
  #form section p {
    margin-bottom: 30px;
  }

  #form section p.explanation-box_txt {
    font-size: 16px;
  }

  #form section div.explanation-box {
    margin: 0 0 20px;
  }

  #form section dt {
    font-size: 22px;
  }

  #form section dt span {
    font-size: 16px;
  }

  #form section dt.required::before,
  #form section h2.required::before,
  #form section h3.required::before,
  #form section .certification-box_txt.required::before {
    font-size: 16px;
    padding: 4px 9px 4px;
    border-radius: 4px;
    margin: 0px 8px 0px 4px;
    font-weight: bold;
  }

  #form section dd input,
  #form section p input {
    height: 50px;
    border-radius: 7px;
    padding: 6px 20px;
    font-size: 20px;
    border: #b5c6d2 solid 2px;
  }

  #form section p.annotation {
    font-size: 16px;
    margin: -24px 0 20px 0;
  }

  #form section .radio label {
    font-size: 22px;
    cursor: pointer;
  }

  #form section.submit ul li button.activeBtn span {
    width: 320px;
    cursor: pointer;
  }

  #form section+section.submit ul li button span {
    border-radius: 35px;
    height: 70px;
    font-size: 26px;
    line-height: 1.7;
  }

  #form section+section.submit {
    padding: 0 0 50px 0;
  }

  /* .submit */
  .submit-mb {
    margin-bottom: 170px;
  }

  /* .footer */

  footer {
    background: #5c7180;
    padding: 14px 0;
    text-align: center;
  }

  footer p.copyright {
    font-size: 13px;
  }

  #form section .zip dd input.zipSearchBtn {
    border-radius: 7px;
    font-size: 18px;
    padding-left: 35px;
    padding-right: 35px;
    height: 50px;
    cursor: pointer;
  }

  .validation-error-txt {
    font-size: 14px;
    margin-top: -24px;
    color: #f44336;
    white-space: pre-line;
  }

  #form .validation-error-box {
    margin: 16px 20px;
    border: solid 2px #f44336;
    padding: 10px 10px 10px 36px;
    color: #f44336;
    background-color: #feeceb;
    font-size: 20px;
    position: relative;
    white-space: pre-line;
  }

  #form .validation-error-box p::before {
    content: "";
    background: url(../public/info_error.svg) left top / 100% auto no-repeat;
    width: 22px;
    height: 22px;
    position: absolute;
    /* right: 10px; */
    top: 10px;
    left: 7px;
    margin-top: 0;
    z-index: 12;
  }

  #form section .calendar p input.calendarBtn {
    width: 50px;
    height: 50px;
    margin-left: 8px;
    border: none;
  }

  #form section .certification-box p input {
    width: 100px;
  }

  #form section div textarea {
    resize: none;
    border-radius: 7px;
    padding: 6px 20px;
    font-size: 20px;
    border: #b5c6d2 solid 2px;
  }

  #form section .select,
  #form section select {
    font-size: 20px;
    height: 50px;
    margin-bottom: 30px;
  }

  #form section select {
    border: #b5c6d2 solid 2px;
    /* margin-bottom: 20px; */
  }

  #form section .select::before {
    content: "";
    background: url(../public/form_select_icn.svg) left top / 100% auto no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0;
  }

  #form section .rs__value-container {
    height: 50px;
  }

  #form section .zip dd input {
    width: 180px;
  }

  .calendar label::before {
    position: absolute;
    content: "";
    right: -43px;
    width: 36px;
    height: 50px;
    background-color: #06c;
    /* background: url("../public/form_calendar_icn.svg") left top / 100% auto no-repeat; */
    background-repeat: no-repeat;
    background-position: center;
  }
}

#form section .rs__input-container {
  position: absolute;
}

#form section input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0px;
  top: 0px;
  left: 0px;
  padding: 0;
  width: 240px;
  height: 36px;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

#form section input[type="month"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0px;
  top: 0px;
  left: 0px;
  padding: 0;
  width: 240px;
  height: 36px;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

#form section input[type="month"]::-webkit-date-and-time-value {
  background: transparent;
  text-align: left;
}

#form section input[type="date"] {
  background: transparent;
  text-align: left;
}

.calendar label {
  position: relative;
}

.loader-bg {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
}

.loader {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  text-align: center;
  color: #fff;
  z-index: 51;
}

#content {
  width:auto;
  line-height: 1.1em;
}

#success {
  white-space: pre-line;
  text-align: left;
}

#parent {
   border : 1px solid #b5c6d2;
   overflow : hidden;
   white-space: pre-line;
   text-align: left;
   padding: 5px;
   margin-bottom: 5px;
}

#showMoreButton {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;
  margin: auto;
  display: block;
}

#addDecription {
  font-size: 12px;
  margin-left: 30px;
}

#userPolicyAgreement {
  margin-top: 10px;
}